import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';

function Footer() {
  const isMobile = useMediaQuery('(max-width:600px)'); 

  return (
    <Grid>
      <Box
        component="footer"
        sx={{
          backgroundColor: '#8f7159',
          color: 'white',
          py: isMobile ? 1 : 0.5,
          px: isMobile ? 1 : 0.5, 
          mt: 'auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'body2' : 'h7'} 
          color="white"
          sx={{
            wordSpacing: isMobile ? '3px' : '5px', 
            letterSpacing: '1px', 
          }}
        >
          Copyright © 2024 B.Com Colleges
        </Typography>
      </Box>
    </Grid>
  );
}

export default Footer;
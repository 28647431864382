import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Modal,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IconButton } from "@mui/material";
import student from "../images/student4.jpg";
import student1 from "../images/student1.jpg";

const colleges = [
  "Christ University - Department of Commerce",
  "St. Joseph's College of Commerce",
  "Mount Carmel College, Bangalore",
  "Jain University - School of Commerce",
  "Jyoti Nivas College Autonomous",
  "Presidency College - Department of Commerce",
  "NMKRV College for Women",
  "The Oxford College of Business Management",
];

const BComApplicationForm = () => {
  const [showForm, setShowForm] = useState(false);

  const handleApplyClick = () => {
    setShowForm(true); 
  };

  const handleCloseForm = () => {
    setShowForm(false); 
  };

  const isMobile = useMediaQuery("(max-width: 600px)"); 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", 
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        marginBottom: "50px",
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          background: "linear-gradient(135deg, #744f3b, #a6755d)",
          color: "#fff",
          width: isMobile ? "100%" : "35%", 
          padding: isMobile ? 0 : 4,
          display: "flex",
          flexDirection: "column",
          borderTopRightRadius: "40px",
          borderBottomRightRadius: "40px",
          marginLeft: isMobile ? "0" : "40px", 
          marginTop: isMobile?'50px':"30px",
          marginRight: isMobile? "10":'auto',
          paddingTop: isMobile?"10px":"auto"
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            textAlign: "center",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Top B.Com Colleges
        </Typography>
        <List sx={{ paddingLeft: 2, flexGrow: 1 }}>
          {colleges.map((college, index) => (
            <ListItem
              key={index}
              sx={{
                padding: "10px 0",
                borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
              }}
            >
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: "#ffd7b5" }} />
              </ListItemIcon>
              <ListItemText primary={college} />
            </ListItem>
          ))}
        </List>
        <Typography
          variant="contained"
          onClick={handleApplyClick}
          sx={{
            color: "white",
            fontWeight: "bold",
            marginTop:'3px',
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "20px",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: "#ffd7b5",
              color: "#744f3b",
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
            },
            width: isMobile ? "80%" : "20%", 
            alignSelf: "center",
            textAlign: "center",
            cursor: "pointer",
        marginBottom:isMobile?"0px":"auto"
          }}
        >
          Apply
        </Typography>
      </Box>

      {/* Right Section with Images */}
      <Box
        sx={{
          width: isMobile ? "100%" : "65%", 
          padding: isMobile ? 2 : 4, 
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        {/* First Section: Text and Image Side by Side */}
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", 
          }}
        >
          {/* Text */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{
                marginLeft: isMobile ? "10px" : "30px",
                marginRight: isMobile ? "25px" : "30px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "sans-serif", color: "brown",textAlign:isMobile?"center":"left" }}
              >
                <strong>Learn, Lead, and get Succeed with Us.</strong>
              </Typography>
              <br />
              Join a community where knowledge meets ambition. With experienced
              faculty and a supportive atmosphere, we empower you to unlock your
              full potential and achieve your dreams.
            </Typography>
          </Grid>

          {/* Image */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <img
              src={student1} 
              alt="Image 1"
              style={{
                width: isMobile ? "80%" : "100%", 
                maxWidth: "400px",
                borderRadius: "50px",
                boxShadow: "0 8px 20px rgba(139, 69, 19, 0.6)",
                alignSelf: isMobile ? "center" : "auto", 
                display: "block", 
                marginRight: isMobile ? "30px" : "initial", 
              }}
            />
          </Grid>
        </Grid>

        {/* Second Section: Text Below or Next to Image */}
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" }, 
            alignItems: "center",
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          {/* Image */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <img
              src={student} 
              alt="Image 2"
              style={{
                width: isMobile ? "80%" : "100%", 
                maxWidth: "400px",
                borderRadius: "50px",
                boxShadow: "0 8px 20px rgba(139, 69, 19, 0.6)",
                alignSelf: isMobile ? "center" : "auto", 
                display: "block", 
                marginRight: isMobile ? "30px" : "initial",
              }}
            />
          </Grid>

          {/* Text */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{
                marginLeft: isMobile ? "10px" : "30px",
                marginRight: isMobile ? "25px" : "30px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "sans-serif", color: "brown",textAlign:isMobile?"center":"left" }}
              >
                <strong>Where Education Meets Innovation in Commerce.</strong>
              </Typography>
              <br />
              Discover the perfect blend of traditional learning and modern
              innovation. Our college emphasizes creativity, analytical
              thinking, and entrepreneurship to prepare you for the challenges
              of the global market.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Modal Form for Application */}
      <Modal
        open={showForm}
        onClose={handleCloseForm}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "80%" : "50%", 
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
            backgroundColor: "#fff",
            borderTopLeftRadius: "100px",
            borderBottomLeftRadius: "100px",
            borderBottomRightRadius: "100px",
            position: "relative", 
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseForm}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#744f3b",
              "&:hover": {
                backgroundColor: "#a6755d",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              textAlign: "center",
              fontFamily: "'Poppins', sans-serif",
              color: "#744f3b",
            }}
          >
            Apply to B.Com Colleges
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: 4,
              textAlign: "center",
              fontFamily: "'Roboto', sans-serif",
              color: "#555",
              lineHeight: 1.5,
            }}
          >
            Begin your journey to a successful career in commerce. Fill out the
            form below to apply to top B.Com colleges.
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: "15px",
              padding: 3,
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                variant="outlined"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#744f3b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#a6755d",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#744f3b",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#744f3b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#a6755d",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#744f3b",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#744f3b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#a6755d",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#744f3b",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 0",
                  borderRadius: "10px",
                  backgroundColor: "#744f3b",
                  "&:hover": {
                    backgroundColor: "#a6755d",
                  },
                }}
              >
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default BComApplicationForm;

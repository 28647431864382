import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, TextField, Button, useMediaQuery } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";

import Mulbeerry1 from "../images/banner.jpeg";
import Mulbeerry2 from "../images/banner1.jpeg";
import Mulbeerry4 from "../images/banner2.jpeg";
import Mulbeerry5 from "../images/banner3.jpeg";

const Banner = () => {
  const images = [Mulbeerry1, Mulbeerry2, Mulbeerry4, Mulbeerry5]; 
  const [currentImageIndex, setCurrentImageIndex] = useState(0); 
  const isMobile = useMediaQuery("(max-width:600px)"); 

 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval); 
  }, [images.length]);

  return (
    <Box
      sx={{
        height: isMobile ? "auto" : "500px", 
        position: "relative",
        backgroundImage: `url(${images[currentImageIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        transition: "background-image 1s ease-in-out",
        color: "white",
        flexDirection: "column", 
      }}
    >
     

      {/* Main Banner Section */}
      <Box
        sx={{
          width: "100%",
          flex: "1", 
          padding: isMobile ? "10px" : "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: isMobile ? "0 10px" : "0 20px", 
          }}
        >
          {/* Left side: Text */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                textAlign: isMobile ? "left" : "center", 
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? "1.5rem" : "3rem", 
                  fontWeight: "bold",
                  textShadow: "2px 2px 8px rgba(155, 103, 67, 1)",
                  fontStyle: "italic",
                }}
              >
                Empowering Minds for the <br />
                World of Finance and Business.
              </Typography>
            </Box>
          </Grid>

          {/* Right side: Form */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: isMobile ? "10px" : "20px", // Adjust padding for mobile
                borderRadius: "8px",
          
                maxWidth: "400px",
                margin: "0 auto",
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  marginBottom: 1,
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#783e20",
                }}
              >
                Apply for B.com Colleges
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#6a4b2d",
                }}
              >
                Kickstart your journey into the world of commerce and business by applying for a B.Com program today!
              </Typography>
              <TextField
                fullWidth
                placeholder="Name"
                variant="outlined"
                name="name"
                sx={{
                  marginBottom: "10px",
                }}
              />
              <TextField
                fullWidth
                placeholder="Phone Number"
                variant="outlined"
                name="phone"
                sx={{
                  marginBottom: "10px",
                }}
              />
              <TextField
                fullWidth
                placeholder="Message"
                variant="outlined"
                name="message"
                multiline
                rows={4}
                sx={{
                  marginBottom: "10px",
                }}
              />
              <Button
                variant="contained"
                startIcon={<CheckCircle />}
                fullWidth
                sx={{
                  backgroundColor: "#783e20",
                  "&:hover": {
                    backgroundColor: "#004b54",
                  },
                }}
              >
                Book Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Banner;

import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Allience from "../images/Allience University.jpeg";
import Ameen from "../images/AI Ameen College.jpeg";
import AKash from "../images/Akash Institution.jpeg";
import Chirst from "../images/Christ University.jpeg";
import New_Horizon from "../images/new_horizon.jpeg";
import Presidency from "../images/Presidency University.jpeg";
import T_Jhon from "../images/T Jhon Group of institution.jpeg";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
  IconButton,
  Button,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Cards = () => {
  const colleges = [
    { name: "AKash Global Science College", image: AKash },
    { name: "Allience School of business", image: Allience },
    { name: "Christ University Bangalore", image: Chirst },
    { name: "New_Horizon College", image: New_Horizon },
    { name: "Presidency University", image: Presidency },
    { name: "T Jhon Group of Institution", image: T_Jhon },
    { name: "AI Ameen commerce college", image: Ameen },
  ];

  const [startIndex, setStartIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null); 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); 

  const handleNext = () => {
    setStartIndex((prev) => (prev + 1) % colleges.length);
  };

  const handlePrev = () => {
    setStartIndex((prev) => (prev - 1 + colleges.length) % colleges.length);
  };

  const getVisibleCards = () => {
    const visibleCards = [];
    if (isMobile) {
    
      visibleCards.push(colleges[startIndex]);
    } else {
      
      for (let i = 0; i < 5; i++) {
        visibleCards.push(colleges[(startIndex + i) % colleges.length]);
      }
    }
    return visibleCards;
  };

  const handleCardClick = () => {
    window.open(
      "https://www.getmycollege.com/colleges?state=karnataka",
      "_blank"
    );
  };

  return (
    <Container
      style={{
        padding: "20px",
        borderRadius: "10px",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      {/* Heading Section */}
      <Typography
        align="center"
        gutterBottom
        sx={{
          fontFamily: "sans-serif",
          fontWeight: "bold",
          color: "brown",
          fontSize: { md: "50px", xs: "30px" },
          marginBottom: "20px",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        Unlock Your Future with a BCom Degree
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{
          fontFamily: "revert-layer",
          fontSize: "18px",
          color: "#5d4037",
          marginBottom: "40px",
          lineHeight: "1.5",
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "50px",
        }}
      >
        Embark on a transformative journey with a Bachelor of Commerce (BCom)
        degree. Designed to sharpen your analytical and problem-solving skills,
        our program empowers you to succeed in the competitive business world.
      </Typography>

      {/* Carousel Controls */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handlePrev}
          style={{
            fontSize: "2rem",
            color: "#8D6E63", 
            backgroundColor: "#D7CCC8", 
            borderRadius: "50%",
            padding: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: isMobile ? "8px" : "auto",
          }}
        >
          <ArrowBackIos />
        </IconButton>

        <Grid container spacing={2} justifyContent="center">
          {getVisibleCards().map((college, index) => {
            const isMiddle = hoveredIndex === null && index === 2; 
            const isHovered = hoveredIndex === index;

            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={isMobile ? 12 : 2.4} 
                key={college.name}
              >
                <Card
                  onMouseEnter={() => setHoveredIndex(index)} 
                  onMouseLeave={() => setHoveredIndex(null)} 
                  onClick={handleCardClick} 
                  style={{
                    width: "230px",
                    transform:
                      isHovered || isMiddle ? "scale(1.1)" : "scale(1)",
                    boxShadow:
                      isHovered || isMiddle
                        ? "0px 4px 20px rgba(0, 0, 0, 0.3)"
                        : "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    borderRadius: "30px",
                    overflow: "hidden",
                    border: `1px solid ${
                      isHovered || isMiddle ? "#8D6E63" : "#D7CCC8"
                    }`,
                    margin: isMobile ? "auto" : "auto",
                    border: "solid 2px brown",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={college.image}
                    alt={college.name}
                    style={{
                      objectFit: "cover",
                      borderRadius: "10px",
                      transform: isHovered ? "scale(1.1)" : "scale(1)",
                      transition: "transform 0.3s",
                    }}
                  />
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        fontFamily: "cursive",
                        cursor: "pointer",
                        fontWeight: isHovered || isMiddle ? "bold" : "normal",
                        color: isHovered || isMiddle ? "#8D6E63" : "#3E2723",
                      }}
                    >
                      {college.name}
                    </Typography>
                    {/* Button Inside the Card */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCardClick} 
                      sx={{
                        marginTop: "10px",
                        backgroundColor: "#8D6E63",
                        "&:hover": {
                          backgroundColor: "#6d4c41",
                        },
                        padding: "6px 16px", 
                        fontSize: "0.8rem", 
                        maxWidth: "120px", 
                        marginLeft: "auto", 
                        marginRight: "auto", 
                      }}
                    >
                      Enquiry
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <IconButton
          onClick={handleNext}
          style={{
            fontSize: "2rem",
            color: "#8D6E63", 
            backgroundColor: "#D7CCC8", 
            borderRadius: "50%",
            padding: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: isMobile ? "8px" : "auto",
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Grid>

      <Button
        onClick={handleCardClick}
        sx={{
          backgroundColor: "#8f7159",
          borderRadius: "50px",
          alignSelf: "center",
          marginLeft: "650px",
          marginTop: "50px",
          color: "white",
          padding: "10px",

        }}
      >
        EXPLORE MORE...
      </Button>
    </Container>
  );
};

export default Cards;

import './App.css';
import { Grid } from '@mui/material';
import Cards from '../src/components/Cards';
import Banner from '../src/components/Banner';
import Forms1 from '../src/components/Forms1';
import Footer from './components/Footer';
import Header1 from './components/Header1';
import About from '../src/components/About';
import AutoPopup from './components/AutopopUp'
import { useEffect, useState } from 'react';
function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(()=>{
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 25000);

    return () => clearTimeout(timeout);
  },[])
 
  return (
    <Grid overflow={'hidden'}>
    
      <Header1/>
      <Banner/>
      <Cards/>
      <About/>
      <Forms1/>
      <Footer/>
      <AutoPopup
          isOpen={isAutoPopupOpen}
          onClose={handleCloseAutoPopup}
      
        />
    </Grid>
  );
}

export default App;

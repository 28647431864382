import React from 'react';
import { Button, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import assets from '../images/logos.png';

const Header1 = () => {
    const isMobile = useMediaQuery('(max-width:600px)'); 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        { label: 'Home', href: '#' },
        { label: 'About Us', href: 'https://www.getmycollege.com/about-us' },
        { label: 'BCom Colleges', href: 'https://www.getmycollege.com/colleges?state=karnataka' },
        { label: 'Contact Us', href: 'https://www.getmycollege.com/contact-us' },
    ];

    return (
        <Grid
            container
            sx={{ backgroundColor: '#8f7159', paddingY: '5px', position: 'relative' }}
            alignItems="center"
        >
            {/* Logo Section */}
            <Grid item xs={6} md={5}>
                <img
                    src={assets}
                    alt="Logo"
                    style={{
                        borderRadius:'50px',
                        width:isMobile? '50px':'70px',
                        height:isMobile? 'auto':"70px",
                        marginLeft: '20px',
                    }}
                />
            </Grid>

            {/* Navigation Section */}
            <Grid
                item
                xs={6}
                md={7}
                sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'flex-end' : 'center',
                    alignItems: 'center',
                    gap: isMobile ? '0' : '50px',
                }}
            >
                {isMobile ? (
                    // Mobile Navigation
                    <>
                        <IconButton
                        style={{marginRight:'10px'}}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{
                                position: 'absolute',
                              
                                right: '0',
                                zIndex: 1300,
                            }}
                        >
                            {menuItems.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={handleMenuClose}
                                    component="a"
                                    href={item.href}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    // Desktop Navigation
                    menuItems.map((item, index) => (
                        <Button
                            key={index}
                            sx={{
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                '&:hover': { textDecoration: 'underline' },
                            }}
                            href={item.href}
                        >
                            {item.label}
                        </Button>
                    ))
                )}
            </Grid>
        </Grid>
    );
};

export default Header1;

import React, { useEffect } from 'react';
import { Grid, Typography, Box} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

 
  const getStarIcons = (percentage) => {
    const totalStars = 5; 
    const filledStars = Math.round((percentage / 100) * totalStars);

    return Array.from({ length: totalStars }, (_, i) => (
      <StarIcon
        key={i}
        sx={{ color: i < filledStars ? 'gold' : 'gray' }}
      />
    ));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '10vh',
        padding: 3,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
       
        <Grid
          item
          xs={12}
          md={8}
          data-aos="fade-up" 
        >
          <Typography variant="h4" fontWeight={'bold'} color="#461c0b" gutterBottom textAlign={'center'}>
            B.com Colleges in Banagalore
          </Typography>
          <Typography variant="h5" fontWeight="bold" color="#5b1f0d" gutterBottom>
            Excellence in Commerce Education
          </Typography>
          <Typography variant="h6" color="Black" mb={2}fontFamily={'sans-serif'}>
            Our B.Com programs are meticulously designed to provide a strong foundation in key areas such as Accounting, Finance, Marketing, Business Strategy, and Data Analytics. Through a unique blend of theoretical knowledge and hands-on experience, we equip our students with the tools needed to excel in today’s dynamic global marketplace. With a team of experienced faculty, industry collaborations, and a vibrant learning environment, we ensure our graduates are ready to face challenges and seize opportunities.
          </Typography>
          <Typography variant="h6" color="Black" mb={2}fontFamily={'sans-serif'}>
            Beyond academics, we believe in nurturing the all-around development of our students. We encourage participation in extracurricular activities, leadership programs, and community initiatives to help students grow as well-rounded individuals. Our faculty members, a blend of academic experts and industry professionals, serve as mentors, guiding students through challenges and inspiring them to reach their fullest potential.
          </Typography>
        </Grid>

        {/* Statistics Section */}
        <Grid
          container
          item
          xs={12}
          md={8}
          spacing={4}
          justifyContent="center"
          data-aos="fade-up" 
        >
          <Grid item xs={4} sm={4}>
            <Typography variant="h4" color="#5b1f0d" fontWeight="bold">
              95%
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Successful Graduates
            </Typography>
            <Box mt={1}>{getStarIcons(95)}</Box>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant="h4" color="#5b1f0d" fontWeight="bold">
              85%
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Placement Rate
            </Typography>
            <Box mt={1}>{getStarIcons(85)}</Box>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant="h4" color="#5b1f0d" fontWeight="bold">
              30+
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Industry Partners
            </Typography>
            <Box mt={1}>{getStarIcons(80)}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;